import { FormFieldStructure } from 'app/forms/structure/form-field.structure';
import { FormStructureType } from 'app/forms/structure/form-structure-types.enum';

export type FormChildStructure = FormFieldStructure | FormGroupStructure | FormArrayStructure;

export interface FormGroupStructure {
  type: FormStructureType;
  children: { [key: string]: FormChildStructure };
  validators?: ValidatorFn<any>[];
  disabled?: boolean;
  key?: string;
  deps?: { [key: string]: any };
  serverErrors?: ValidationErrorJSON[];
}

type FormGroupStructureOpts = Partial<Pick<FormGroupStructure, 'key' | 'deps'>>;

export const groupStructure = (children = {}, validators = [], disabled = false, opts: FormGroupStructureOpts = {}): FormGroupStructure => ({
  type: FormStructureType.Group,
  children, validators, disabled,
  ...opts
});
