import { FormStructureType } from 'app/forms/structure/form-structure-types.enum';

export interface FormFieldStructure {
  type: FormStructureType;
  dirty?: boolean;
  touched?: boolean;
  initialValue?: any;
  value?: any;
  validators?: ValidatorFn<any>[];
  disabled?: boolean;
}
export interface FormFieldStructureOpts {
  dirty?: boolean;
  touched?: boolean;
  value?: any;
  validators?: ValidatorFn<any>[];
  disabled?: boolean;
}

export const fieldStructure = (initialValue = '' as any, opts: FormFieldStructureOpts = {}): FormFieldStructure => ({
  type: FormStructureType.Field,
  initialValue,
  ...opts
});
